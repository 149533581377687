<template>
  <div>
    <header-top-dashboard primary>
      <v-row class="mt-0">
        <v-col
          v-for="(item, i) in miniCards"
          :key="i"
          cols="12"
          lg="3"
          md="6"
          class="py-0"
        >
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-stats-padding">
              <v-row no-gutters>
                <v-col>
                  <div
                    class="font-weight-600 text-muted text-uppercase text-h5"
                  >
                    {{ item.title }}
                  </div>
                  <p class="font-weight-600 text-h2 text-typo mb-0">
                    {{ item.value }}
                  </p>
                </v-col>
                <v-col class="text-right">
                  <v-avatar :color="item.gradient">
                    <v-icon size="20" class="text-white">
                      {{ item.icon }}
                    </v-icon>
                  </v-avatar>
                </v-col>
              </v-row>

              <p class="mt-4 mb-0 text-body font-weight-light">
                <span class="text-success me-2">
                  {{ item.percentUpSinceLastMonth }} %
                </span>
                Since last month
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <v-row class="mt-0">
        <v-col lg="8" class="pt-0">
          <v-card class="card-shadow bg-default">
            <div class="card-header-padding">
              <v-row no-gutters>
                <v-col>
                  <div
                    class="font-weight-600 text-light text-uppercase text-h6 ls-1 mb-1"
                  >
                    Overview
                  </div>
                  <p class="font-weight-600 text-h3 text-white mb-0">
                    Sales Value
                  </p>
                </v-col>
                <v-col class="d-flex align-center justify-end">
                  <!-- <v-btn
                    elevation="0"
                    :ripple="false"
                    class="me-4 font-weight-600 text-capitalize btn-neutral"
                    :class="{ 'active-status': bigLineChart.activeIndex === 0 }"
                    @click.prevent="initBigChart(0)"
                    color="#fff"
                    >Month
                  </v-btn> -->
                </v-col>
              </v-row>
            </div>
            <v-card-text class="card-padding">
              <div class="chart-area">
                <canvas :height="350" :id="salesChartID"></canvas>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4" class="pt-0">
          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <div
                class="font-weight-600 text-muted text-uppercase text-h6 ls-1 mb-1">
                Performance
              </div>
              <p class="font-weight-600 text-typo text-h3 mb-0">Total Orders</p>
            </div>
            <v-card-text class="card-padding">
              <div class="chart-area">
                <canvas :height="350" :id="ordersChartID"></canvas>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
// import MediaComment from "@/components/MediaComment.vue";
import { ordersChart } from "@/components/Charts/Chart";
import Chart from "chart.js/auto";
// import MembersCard from "./Widgets/MembersCard.vue";
// import ToDoList from "./Widgets/ToDoList.vue";
// import ProgressTrack from "./Widgets/ProgressTrack.vue";
let chart;

export default {
  name: "Dashboard",
  components: {
    HeaderTopDashboard,
    // MediaComment,
    // MembersCard,
    // ToDoList,
    // ProgressTrack,
  },
  data: function () {
    return {
      salesChartID: "salesChart",
      ordersChartID: "ordersChart",
      bigLineChart: {
        allData: [[], []],
        activeIndex: 0,
      },
      miniCards: [],
      socialTraffic: [
        {
          referral: "Facebook",
          visitors: "1,480",
          progressValue: "60%",
          progressColor: "bg-gradient-danger",
        },
        {
          referral: "Facebook",
          visitors: "5,480",
          progressValue: "70%",
          progressColor: "bg-gradient-success",
        },
        {
          referral: "Google",
          visitors: "4,807",
          progressValue: "80%",
          progressColor: "bg-gradient-primary",
        },
        {
          referral: "Instagram",
          visitors: "3,678",
          progressValue: "75%",
          progressColor: "bg-gradient-info",
        },
        {
          referral: "Twitter",
          visitors: "2,645",
          progressValue: "30%",
          progressColor: "bg-gradient-warning",
        },
      ],
      visits: [
        {
          pageName: "/argon/",
          visitors: "4,569",
          uniqueUsers: "340",
          bounceColor: "success",
          bounceIcon: "fas fa-arrow-up",
          bounceRate: "46,53%",
        },
        {
          pageName: "/argon/index.html",
          visitors: "3,985",
          uniqueUsers: "319",
          bounceColor: "warning",
          bounceIcon: "fas fa-arrow-down",
          bounceRate: "46,53%",
        },
        {
          pageName: "/argon/charts.html",
          visitors: "3,513",
          uniqueUsers: "294",
          bounceColor: "warning",
          bounceIcon: "fas fa-arrow-down",
          bounceRate: "36,49%",
        },
        {
          pageName: "/argon/tables.html",
          visitors: "2,050",
          uniqueUsers: "147",
          bounceColor: "success",
          bounceIcon: "fas fa-arrow-up",
          bounceRate: "50,87%",
        },
        {
          pageName: "/argon/profile.html",
          visitors: "1,795",
          uniqueUsers: "190",
          bounceColor: "danger",
          bounceIcon: "fas fa-arrow-down",
          bounceRate: "46,53%",
        },
      ],
      projects: [
        {
          id: 0,
          project: "Argon Design System",
          imgSrc: require("@/assets/img/bootstrap.jpg"),
          budget: "$2500",
          status: "pending",
          statusColor: "warning",
          avatars: [
            {
              avatarSrc: require("@/assets/img/team-1.jpg"),
              tooltipName: "Ryan Thompson",
            },
            {
              avatarSrc: require("@/assets/img/team-2.jpg"),
              tooltipName: "Romina Hadid",
            },
            {
              avatarSrc: require("@/assets/img/team-3.jpg"),
              tooltipName: "Alexander Smith",
            },
            {
              avatarSrc: require("@/assets/img/team-4.jpg"),
              tooltipName: "Jessica Doe",
            },
          ],
          progressValue: "90",
          progressColor: "#fb6340",
        },
        {
          id: 1,
          project: "Angular Now UI Kit PRO",
          imgSrc: require("@/assets/img/angular.jpg"),
          budget: "$1800",
          status: "completed",
          statusColor: "success",
          avatars: [
            {
              avatarSrc: require("@/assets/img/team-1.jpg"),
              tooltipName: "Ryan Thompson",
            },
            {
              avatarSrc: require("@/assets/img/team-2.jpg"),
              tooltipName: "Romina Hadid",
            },
            {
              avatarSrc: require("@/assets/img/team-3.jpg"),
              tooltipName: "Alexander Smith",
            },
            {
              avatarSrc: require("@/assets/img/team-4.jpg"),
              tooltipName: "Jessica Doe",
            },
          ],
          progressValue: "100",
          progressColor: "#2dce89",
        },
        {
          id: 2,
          project: "Black Dashboard",
          imgSrc: require("@/assets/img/sketch.jpg"),
          budget: "$3150",
          status: "delayed",
          statusColor: "danger",
          avatars: [
            {
              avatarSrc: require("@/assets/img/team-1.jpg"),
              tooltipName: "Ryan Thompson",
            },
            {
              avatarSrc: require("@/assets/img/team-2.jpg"),
              tooltipName: "Romina Hadid",
            },
            {
              avatarSrc: require("@/assets/img/team-3.jpg"),
              tooltipName: "Alexander Smith",
            },
            {
              avatarSrc: require("@/assets/img/team-4.jpg"),
              tooltipName: "Jessica Doe",
            },
          ],
          progressValue: "73",
          progressColor: "#f5365c",
        },
        {
          id: 3,
          project: "React Material Dashboard",
          imgSrc: require("@/assets/img/react.jpg"),
          budget: "$4400",
          status: "on schedule",
          statusColor: "info",
          avatars: [
            {
              avatarSrc: require("@/assets/img/team-1.jpg"),
              tooltipName: "Ryan Thompson",
            },
            {
              avatarSrc: require("@/assets/img/team-2.jpg"),
              tooltipName: "Romina Hadid",
            },
            {
              avatarSrc: require("@/assets/img/team-3.jpg"),
              tooltipName: "Alexander Smith",
            },
            {
              avatarSrc: require("@/assets/img/team-4.jpg"),
              tooltipName: "Jessica Doe",
            },
          ],
          progressValue: "90",
          progressColor: "#11cdef",
        },
        {
          id: 4,
          project: "Vue Paper UI Kit PRO",
          imgSrc: require("@/assets/img/vue.jpg"),
          budget: "$2200",
          status: "completed",
          statusColor: "success",
          avatars: [
            {
              avatarSrc: require("@/assets/img/team-1.jpg"),
              tooltipName: "Ryan Thompson",
            },
            {
              avatarSrc: require("@/assets/img/team-2.jpg"),
              tooltipName: "Romina Hadid",
            },
            {
              avatarSrc: require("@/assets/img/team-3.jpg"),
              tooltipName: "Alexander Smith",
            },
            {
              avatarSrc: require("@/assets/img/team-4.jpg"),
              tooltipName: "Jessica Doe",
            },
          ],
          progressValue: "100",
          progressColor: "#2dce89",
        },
        {
          id: 5,
          project: "Argon Design System",
          imgSrc: require("@/assets/img/bootstrap.jpg"),
          budget: "$2500",
          status: "pending",
          statusColor: "warning",
          avatars: [
            {
              avatarSrc: require("@/assets/img/team-1.jpg"),
              tooltipName: "Ryan Thompson",
            },
            {
              avatarSrc: require("@/assets/img/team-2.jpg"),
              tooltipName: "Romina Hadid",
            },
            {
              avatarSrc: require("@/assets/img/team-3.jpg"),
              tooltipName: "Alexander Smith",
            },
            {
              avatarSrc: require("@/assets/img/team-4.jpg"),
              tooltipName: "Jessica Doe",
            },
          ],
          progressValue: "60",
          progressColor: "#fb6340",
        },
        {
          id: 6,
          project: "Angular Now UI Kit PRO",
          imgSrc: require("@/assets/img/angular.jpg"),
          budget: "$1800",
          status: "completed",
          statusColor: "success",
          avatars: [
            {
              avatarSrc: require("@/assets/img/team-1.jpg"),
              tooltipName: "Ryan Thompson",
            },
            {
              avatarSrc: require("@/assets/img/team-2.jpg"),
              tooltipName: "Romina Hadid",
            },
            {
              avatarSrc: require("@/assets/img/team-3.jpg"),
              tooltipName: "Alexander Smith",
            },
            {
              avatarSrc: require("@/assets/img/team-4.jpg"),
              tooltipName: "Jessica Doe",
            },
          ],
          progressValue: "100",
          progressColor: "#2dce89",
        },
        {
          id: 7,
          project: "Black Dashboard",
          imgSrc: require("@/assets/img/sketch.jpg"),
          budget: "$3150",
          status: "delayed",
          statusColor: "danger",
          avatars: [
            {
              avatarSrc: require("@/assets/img/team-1.jpg"),
              tooltipName: "Ryan Thompson",
            },
            {
              avatarSrc: require("@/assets/img/team-2.jpg"),
              tooltipName: "Romina Hadid",
            },
            {
              avatarSrc: require("@/assets/img/team-3.jpg"),
              tooltipName: "Alexander Smith",
            },
            {
              avatarSrc: require("@/assets/img/team-4.jpg"),
              tooltipName: "Jessica Doe",
            },
          ],
          progressValue: "73",
          progressColor: "#f5365c",
        },
        {
          id: 8,
          project: "Angular Now UI Kit PRO",
          imgSrc: require("@/assets/img/angular.jpg"),
          budget: "$1800",
          status: "completed",
          statusColor: "success",
          avatars: [
            {
              avatarSrc: require("@/assets/img/team-1.jpg"),
              tooltipName: "Ryan Thompson",
            },
            {
              avatarSrc: require("@/assets/img/team-2.jpg"),
              tooltipName: "Romina Hadid",
            },
            {
              avatarSrc: require("@/assets/img/team-3.jpg"),
              tooltipName: "Alexander Smith",
            },
            {
              avatarSrc: require("@/assets/img/team-4.jpg"),
              tooltipName: "Jessica Doe",
            },
          ],
          progressValue: "100",
          progressColor: "#2dce89",
        },
      ],
      mediaComment: [
        {
          name: "Michael Lewis",
          avatarImg: "team-1.jpg",
          description:
            "Cras sit amet nibh libero nulla vel metus scelerisque ante.",
          likeBtnIcon: "ni-like-2",
          likeBtnText: "3 Likes",
          shareBtnIcon: "ni-curved-next",
          shareBtnText: "2 shares",
        },
        {
          name: "Jessica Stones",
          avatarImg: "team-2.jpg",
          description:
            "Cras sit amet nibh libero nulla vel metus scelerisque ante.",
          likeBtnIcon: "ni-like-2",
          likeBtnText: "10 Likes",
          shareBtnIcon: "ni-curved-next",
          shareBtnText: "1 shares",
        },
      ],
      avatarsActivity: [
        {
          imgID: 1,
          imgSrc: require("@/assets/img/team-1.jpg"),
          tooltipName: "Michael Lewis",
        },
        {
          imgID: 2,
          imgSrc: require("@/assets/img/team-2.jpg"),
          tooltipName: "Audrey Love",
        },
        {
          imgID: 3,
          imgSrc: require("@/assets/img/team-3.jpg"),
          tooltipName: "Jessica Rowland",
        },
      ],
      socialActivity: [
        {
          icon: "ni-like-2",
          text: "150",
          color: "primary",
        },
        {
          icon: "ni-chat-round",
          text: "36",
          color: "dark",
        },
        {
          icon: "ni-curved-next",
          text: "12",
          color: "dark",
        },
      ],
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    oneActive() {
      this.firstBtnActive = true;
      this.secondBtnActive = !this.firstBtnActive;
    },
    twoActive() {
      this.secondBtnActive = true;
      this.firstBtnActive = !this.secondBtnActive;
    },
    initBigChart(index) {
      chart.update(
        (chart.config.data.datasets[0].data = this.bigLineChart.allData[index])
      );
      this.bigLineChart.activeIndex = index;
    },
  },
  async mounted() {
    const salesMonths = [];
    const ordersChartData = [];
    const ordersChartLabels = [];

    await this.$http
      .get("/stats", this.sessionStore.getHttpConfig)
      .then((result) => {
        let totalOrdersPercentUpSinceLastMonth = 0;
        if (result.data.ordersCount.lastMonth !== 0) {
          totalOrdersPercentUpSinceLastMonth = (
            (result.data.ordersCount.monthToDate /
              result.data.ordersCount.lastMonth) *
            100
          ).toFixed(2);
        } else {
          totalOrdersPercentUpSinceLastMonth = (
            result.data.ordersCount.monthToDate * 100
          ).toFixed(2);
        }

        this.miniCards.push({
          title: "Total Orders",
          value: `# ${result.data.ordersCount.monthToDate}`,
          percentUpSinceLastMonth: totalOrdersPercentUpSinceLastMonth,
          gradient: "bg-gradient-info",
          icon: "ni-active-40",
        });

        let newShopsPercentUpSinceLastMonth = 0;
        if (result.data.shopsCount.lastMonth !== 0) {
          newShopsPercentUpSinceLastMonth = (
            (result.data.shopsCount.monthToDate /
              result.data.shopsCount.lastMonth) *
            100
          ).toFixed(2);
        } else {
          newShopsPercentUpSinceLastMonth = (
            result.data.shopsCount.monthToDate * 100
          ).toFixed(2);
        }

        this.miniCards.push({
          title: "New Shops",
          value: `# ${result.data.shopsCount.monthToDate}`,
          percentUpSinceLastMonth: newShopsPercentUpSinceLastMonth,
          gradient: "bg-gradient-info",
          icon: "ni-chart-pie-35",
        });

        let salesPercentUpSinceLastMonth = 0;
        if (result.data.ordersCount.lastMonth !== 0) {
          salesPercentUpSinceLastMonth = (
            (result.data.salesCount.monthToDate /
              result.data.salesCount.lastMonth) *
            100
          ).toFixed(2);
        } else {
          salesPercentUpSinceLastMonth = (
            result.data.salesCount.monthToDate * 100
          ).toFixed(2);
        }

        this.miniCards.push({
          title: "Sales",
          value: `# ${result.data.salesCount.monthToDate}`,
          percentUpSinceLastMonth: salesPercentUpSinceLastMonth,
          gradient: "bg-gradient-info",
          icon: "ni-money-coins",
        });

        let salesPerformancePercentUpSinceLastMonth = 0;
        if (result.data.salesValue.lastMonth !== 0) {
          salesPerformancePercentUpSinceLastMonth = (
            (result.data.salesValue.monthToDate /
              result.data.salesValue.lastMonth) *
            100
          ).toFixed(2);
        } else {
          salesPerformancePercentUpSinceLastMonth = (
            result.data.salesValue.monthToDate * 100
          ).toFixed(2);
        }

        this.miniCards.push({
          title: "Sales Performce",
          value: `R${result.data.salesValue.monthToDate.toFixed(2)}`,
          percentUpSinceLastMonth: salesPerformancePercentUpSinceLastMonth,
          gradient: "bg-gradient-info",
          icon: "ni-chart-bar-32",
        });

        for (
          let i = result.data.salesValueByMonth.lastSixMonthSummary.length - 1;
          i >= 0;
          i--
        ) {
          this.bigLineChart.allData[0].push(
            result.data.salesValueByMonth.lastSixMonthSummary[i].value
          );

          salesMonths.push(
            result.data.salesValueByMonth.lastSixMonthSummary[i].date
          );
        }

        for (
          let i = result.data.salesCountByMonth.lastSixMonthSummary.length - 1;
          i >= 0;
          i--
        ) {
          ordersChartData.push(
            result.data.salesCountByMonth.lastSixMonthSummary[i].count
          );

          ordersChartLabels.push(
            result.data.salesCountByMonth.lastSixMonthSummary[i].date
          );
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
      });

    chart = new Chart(
      document.getElementById(this.salesChartID).getContext("2d"),
      {
        type: "line",
        data: {
          labels: salesMonths,
          datasets: [
            {
              label: "Performance",
              tension: 0.4,
              borderWidth: 4,
              borderColor: "#5e72e4",
              pointRadius: 0,
              backgroundColor: "transparent",
              barPercentage: 1.6,
              data: this.bigLineChart.allData[0],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
          },
          scales: {
            y: {
              grid: {
                color: "rgba(255, 255, 255, .2)",
                drawBorder: false,
                offset: true,
                borderDash: [2],
              },
              ticks: {
                padding: 10,
                color: "#8898aa",
                font: "Open Sans",
              },
            },

            x: {
              grid: {
                color: "transparent",
                drawBorder: false,
              },
              ticks: {
                padding: 10,
                color: "#8898aa",
                font: "Open Sans",
              },
            },
          },
          layout: {
            padding: 0,
          },
        },
      }
    );
    ordersChart.createChart(
      this.ordersChartID,
      ordersChartLabels,
      ordersChartData
    );

    setTimeout(() => {
      this.initBigChart(0);
    }, 500);
  },
};
</script>
